.field-grid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px; 
}

.field-row {
  display: flex;
}

.field-cell {
  width: 50px;
  height: 50px;
  border: 1px solid black;
  margin: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.field-cell:hover{
  background-color: rgb(239, 239, 130);
}

.title {
  text-align: center;
  color: rgb(105, 165, 13);
  font-family: "Roboto", sans-serif; 
  font-size: 24px; 
}

.container {
  background-color: beige;
  text-align: center;
  font-family: "Roboto", sans-serif; 
}

.time {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  font-weight: bold;
  margin-bottom: 10px;
}

.inventory-grid {
  margin-top: 20px;
}

.inventory-slots {
  display: grid;
  grid-template-columns: repeat(9, 1fr); 
  gap: 10px;
}

.inventory-slot {
  width: 50px;
  height: 50px;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.crop-selection {
  margin-bottom: 20px;
  text-align: center;
}

.crop-list {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.crop-item {
  cursor: pointer;
  text-align: center;
}

.crop-item img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-bottom: 5px;
}

.selected-crop {
  margin-bottom: 10px;
}

.market-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  gap: 10px; 
}

.market-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border: 1.5px solid black;
  border-radius: 3px;
  cursor: pointer;
  background-color: beige;
  height: 210px;
}

.market-item:hover {
  background-color: rgb(239, 239, 130);
  zoom: 105%;
  border-bottom: 10px solid black;
  transition: all 0.3s ease-out;
}

.market-item img {
  width: 100px; 
  height: 100px;
  object-fit: contain;
  margin-bottom: 10px;
}

.meter-title {
  margin-top: 40px;
  height: 30px;
}

.weather {
  text-align: left;
}
body {
  font-family: 'Roboto', sans-serif;
  background-color: #f7f7f7;
  color: #333;
}
.container {
  background-color: beige;
  box-shadow: 0px 4px 8px rgba(0,0,0,0.1);
  border-radius: 8px;
  padding: 20px;
  margin: 20px;
}

.title {
  font-family: 'Roboto Slab', serif;
  color: #4caf50;
  margin-bottom: 20px;
}

button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #388e3c;
}
@media (max-width: 768px) {
  .inventory-slots {
    grid-template-columns: repeat(3, 1fr);
  }
}