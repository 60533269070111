

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: transparent; 
    position: fixed; 
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000; 
}

.header-logo {
    display: flex;
    align-items: center;
}

.logo-image {
    height: 50px; 
}

.header-nav {
    display: flex;
    gap: 20px;
}

.nav-link {
    text-decoration: none;
    color: #fff; 
    padding: 5px 10px;
    transition: all 0.3s ease;
    background-color: rgba(0, 0, 0, 0.3); 
    border-radius: 4px;
}

.nav-link:hover {
    background-color: rgba(0, 0, 0, 0.7); 
    border-radius: 4px;
}
