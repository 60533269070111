

.field-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px;
  }
  
  .field-row {
    display: flex;
  }
  
  .field-cell {
    width: 50px;
    height: 50px; 
    border: 1px solid #8B4513; 
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2px;
    cursor: pointer;
    transition: background-color 0.3s;
    background-color: rgba(139, 69, 19, 0.5); 
  }
  
  .player1-cell {
    background-color: red;
  }
  
  .player2-cell {
    background-color: blue;
  }
  

  