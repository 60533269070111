.home-container {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.video-background {
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.content {
  position: relative;
  z-index: 1;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.content h1 {
  color: white;
  -webkit-text-stroke: 1px black;
  font-size: 3em;
  margin-bottom: 20px;
}
.button-container {
  background-color: rgba(0, 0, 0, 0.5);  
  border-radius: 15px;
  padding: 10px;
  margin: 10px 0;  
  display: inline-block;
}

button {
  margin: 0;  
  padding: 10px 20px;
  font-size: 1.2em;
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: white;
  border-radius: 8px;
  transition: background-color 0.3s;
}
button:hover {
  background-color: #28a745;  
}
.button-container .home-button {
  margin: 0;  
  padding: 10px 20px;
  font-size: 1.2em;
  cursor: pointer;
  background-color: transparent; 
  border: none;
  color: white;
  border-radius: 8px;
  transition: background-color 0.3s;
}

.button-container .home-button:hover {
  background-color: #28a745;  
}

.home-logo {
  max-width: 400px; 
  width: auto;
  height: auto; 
  margin-bottom: 20px;
}
